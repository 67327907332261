import { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import {
  Chart as ChartJS,
  Colors,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

const GraficoAnalisis = ({ data, serp }) => {
  // console.log(data);
  // console.log(serp);

  const [radioValue, setRadioValue] = useState("1");
  // const [datos, setDatos] = useState(null);
  const radios = [
    { name: "Dominio", value: "1" },
    { name: "Economía3", value: "2" },
  ];
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `Historial ${radioValue === "1" ? "Dominio" : "Economía3"}`,
      },
    },
  };

  const labels = data.map((f) => f.fecha);
  const hoy = new Date().toLocaleDateString("en-GB");
  // const hoy = "28/11/2023";
  console.log("fechas", labels);
  // console.log("hoy", hoy);
  const labelHoy = labels.find((f) => f === hoy);
  if (!labelHoy) {
    labels.push(hoy);
    data.push({ fecha: hoy, data: serp });
  }
  console.log(labelHoy);
  let valores = {};
  for (const fecha of data) {
    for (const kw of fecha.data) {
      const keyword = kw.keyword.toLowerCase();
      if (!valores[keyword]) {
        valores[keyword] = [];
      }
      if (radioValue === "1") {
        valores[keyword].push(
          kw.posDominio !== "-" ? Number(kw.posDominio) : null
        );
      } else {
        // console.log(fecha.fecha, kw.keyword, kw.posEco3);
        valores[keyword].push(kw.posEco3 !== "-" ? Number(kw.posEco3) : null);
      }
    }
  }
  // console.log(valores);
  // setDatos({
  const datos = {
    labels,
    datasets: Object.keys(valores).map((v) => {
      return {
        label: v,
        data: valores[v],
      };
    }),
  };
  // console.log(datos.labels);
  // console.log(datos.datasets);

  return (
    <Container>
      <Row>
        <Col>
          <h4>Evolución</h4>
          <ButtonGroup>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-dark"}
                name="radio"
                size="sm"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          <Line options={options} data={datos} />;
        </Col>
      </Row>
      {datos.labels.length > 0 && (
        <Row>
          <Col>
            <Table striped>
              <thead>
                <tr>
                  <th>Fecha</th>
                  {datos.datasets?.map((dataset, ix1) => (
                    <th>{dataset.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {datos.labels?.map((fecha, ix1) => (
                  <tr key={ix1}>
                    <td>{fecha}</td>
                    {datos.datasets?.map((dataset, ix2) => (
                      <td>
                        {dataset.data[ix1] === null ? "-" : dataset.data[ix1]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default GraficoAnalisis;
