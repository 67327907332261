import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { config } from "./config";
import ResultadoAnalisis from "./components/ResultadoAnalisis";
import LoadingSpinner from "./components/LoadingSpinner";
import { NavLink, Navigate } from "react-router-dom";
import { useUser } from "./userContext";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";

function toUnicode(theString) {
  var unicodeString = "";
  for (var i = 0; i < theString.length; i++) {
    var theUnicode = theString.charCodeAt(i).toString(16).toUpperCase();
    while (theUnicode.length < 4) {
      theUnicode = "0" + theUnicode;
    }
    theUnicode = "\\u" + theUnicode;
    unicodeString += theUnicode;
  }
  return unicodeString;
}
function Convertir(pl) {
  pl = pl.replaceAll("á", "a");
  pl = pl.replaceAll("é", "e");
  pl = pl.replaceAll("í", "i");
  pl = pl.replaceAll("ó", "o");
  pl = pl.replaceAll("ú", "u");
  pl = pl.replaceAll("ñ", "n");
  return pl;
}

const AnalizarModule = () => {
  const { isAuthenticated, user } = useUser();
  const [nombre, setNombre] = useState("");
  const [dominio, setDominio] = useState("");
  const [dominioEco3, setDominioEco3] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [keywords, setKeywords] = useState("");
  const [analisis, setAnalisis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isIdeas, setIdeas] = useState(false);
  const [puedeGuardar, setpuedeGuardar] = useState(false);
  const navigate = useNavigate();

  const verResultados = async (data) => {
    setIdeas(false);
    setIsLoading(true);
    const payload = {
      nombre,
      dominio,
      dominioEco3,
      descripcion,
      keywords: keywords.toLowerCase(),
      usuario: user.email,
    };
    let pl = Convertir(JSON.stringify(payload));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: pl, //JSON.stringify(payload),
      // body: payload,
    };

    fetch(`${config.api}/analizar`, options)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setAnalisis(data);
        setIsLoading(false);
        console.log(data?.keywords.length);
        if (data?.keywords.length > 0) {
          setpuedeGuardar(true);
        } else {
          setpuedeGuardar(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const guardarResultados = async (data) => {
    setIsLoading(true);
    const payload = {
      nombre,
      dominio,
      dominioEco3,
      descripcion,
      keywords,
      usuario: user.email,
    };
    let pl = Convertir(JSON.stringify(payload));

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: pl,
    };
    fetch(`${config.api}/guardar`, options)
      .then((response) => response.json())
      .then((data) => {
        setAnalisis(null);
        setIsLoading(false);
        setpuedeGuardar(false);
        navigate("/");
        // window.location = "/analizar";
      })
      .catch((error) => {
        setIsLoading(false);
        setpuedeGuardar(false);
        console.error(error);
      });
  };

  const verIdeas = async (data) => {
    setIsLoading(true);
    setIdeas(true);
    const payload = {
      nombre,
      dominio,
      descripcion,
      keywords: keywords.toLowerCase(),
      usuario: user.email,
    };
    let pl = Convertir(JSON.stringify(payload));

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: pl,
    };
    fetch(`${config.api}/ideas`, options)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setAnalisis(data);
        setIsLoading(false);
        console.log(data?.keywords.length);
        setpuedeGuardar(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  return (
    <>
      {!isAuthenticated && <Navigate to="/login" />}
      <Container>
        <Row>
          <Col>
            <h2>Herramienta análisis keyword ECO3</h2>
            <NavLink to="/">Inicio</NavLink>
            <Breadcrumb>
              <Breadcrumb.Item active>Realizar análisis</Breadcrumb.Item>
            </Breadcrumb>
            <hr></hr>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form>
              <InputGroup className="mb-3">
                <InputGroup.Text className="lbl-campos">
                  Empresa del análisis
                </InputGroup.Text>
                <Form.Control
                  placeholder="Nombre de la empresa para realizar el análisis"
                  type="input"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text className="lbl-campos">
                  Descripción del análisis
                </InputGroup.Text>
                <Form.Control
                  placeholder="Breve descripción del análisis"
                  type="input"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text className="lbl-campos">
                  URL Seguimiento Empresa
                </InputGroup.Text>
                <Form.Control
                  placeholder="URL para localizar en los resultados de búsqueda"
                  type="input"
                  value={dominio}
                  onChange={(e) => setDominio(e.target.value)}
                />
                <Button
                  className="mx-2"
                  variant="success"
                  size="sm"
                  onClick={verIdeas}
                  disabled={isLoading}
                >
                  Obtener ideas de palabras clave
                </Button>
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text className="lbl-campos">
                  URL Seguimiento ECO3
                </InputGroup.Text>
                <Form.Control
                  placeholder="URL para localizar en los resultados de búsqueda"
                  type="input"
                  value={dominioEco3}
                  onChange={(e) => setDominioEco3(e.target.value)}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text className="lbl-campos">
                  Listado de palabras clave del análisis
                </InputGroup.Text>
                <Form.Control
                  placeholder="Listado de palabras clave separadas por coma"
                  type="input"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </InputGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="primary"
              onClick={verResultados}
              disabled={isLoading}
            >
              Ver resultados
            </Button>{" "}
          </Col>
          <Col>
            <Button
              variant="success"
              onClick={guardarResultados}
              hidden={!puedeGuardar}
            >
              Guardar
            </Button>{" "}
          </Col>
        </Row>
        {isLoading && (
          <Row>
            <Col>
              <LoadingSpinner />
            </Col>
          </Row>
        )}
        {analisis && (
          <Row>
            <Col>
              <ResultadoAnalisis data={analisis} ideas={isIdeas} />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default AnalizarModule;
