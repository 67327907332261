import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useState, useEffect } from "react";
import { BsLink } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Sparklines, SparklinesLine } from "react-sparklines";

const ResultadoAnalisis = ({ data, serp, ideas }) => {
  const [fecha, setFecha] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [serpTop, setSerpTop] = useState([]);
  const [serpTopTitulo, setSerpTopTitulo] = useState("");

  useEffect(() => {
    if (!data) return;

    if (ideas) {
      console.log("Ideas", data.keywords);
      const hoy = new Date().toLocaleDateString("en-GB");
      setFecha(hoy);
      setKeywords(data.keywords);
    } else {
      setFecha(data.fechas[0].fecha);
      setKeywords(data.fechas[0].data);
    }

    // setKeywords(data.keywords);
  }, [data]);

  useEffect(() => {
    if (!keywords || keywords.length === 0) return;
    if (!serp) return;
    // console.log("keywords", keywords);
    // console.log("serp", serp);
    const newData = keywords.map((d) => {
      // console.log(`buscando kw: ${d.keyword}`);
      const serpKw = serp.find(
        (s) => s.keyword.toLowerCase() === d.keyword.toLowerCase()
      );
      if (serpKw) {
        d.serp.posDominioHoy = serpKw.posDominio;
        d.serp.posEco3Hoy = serpKw.posEco3;
        d.serp.urlDominioHoy = serpKw.serp.urlDominio;
        d.serp.urlEco3Hoy = serpKw.serp.urlEco3;
        d.serp.topHoy = serpKw.serp.top;
        return d;
      } else {
        console.log("No encontro kw", d.keyword);
        return d;
      }
    });

    // console.log("newData", newData);
    setKeywords(newData);
  }, [serp]);

  // const urlDominio = (s) => {
  //   if (Number(s.posDominio) > 0) {
  //     return s.urlDominio;
  //   }
  // };

  const verTop = async (lista, keyword, tipo) => {
    console.log(lista);
    if (tipo === "fecha") {
      setSerpTopTitulo(`TOP de keyword: '${keyword}' (${fecha})`);
    } else {
      setSerpTopTitulo(`TOP de keyword: '${keyword}' (Hoy)`);
    }
    setSerpTop(lista);
  };

  const sparklinkeData = (kw, tipo) => {
    if (!data) {
      console.log("sparklinkeData", kw, tipo, null);
      return [];
    }
    kw = kw.toLowerCase();
    const result = [];
    if (data.fechas.length < 2) {
      console.log("sparklinkeData < 2", kw, tipo);
      return result;
    }

    for (const f of data.fechas) {
      const dato = f.data.find((k) => k.keyword.toLowerCase() == kw);
      if (dato) {
        const valor = tipo === "dominio" ? dato.posDominio : dato.posEco3;
        if (valor != "-") {
          result.push(valor == "-" ? 0 : Number(valor));
        }
      }
    }
    // console.log("sparklinkeData", kw, tipo, result);
    // if (result.length == 2) result.push(40);
    return result;
  };

  // const urlEco3 = (s) => {
  //   if (Number(s.posEco3) > 0) {
  //     return s.urlEco3;
  //   }
  // };

  return (
    <>
      <Table striped>
        <thead>
          <tr>
            <th>Keyword</th>
            <th>Búsquedas mensuales</th>
            <th>Media Búsquedas mensuales últimos 12 meses</th>
            <th>Competencia ADS</th>
            <th>Indice Competencia ADS</th>
            <th>CPC Bajo</th>
            <th>CPC Alto {ideas}</th>
            {!ideas && (
              <>
                <th>Pos/Dominio ({fecha})</th>
                <th>Pos/Eco3 ({fecha})</th>
                <th>Top ({fecha})</th>
              </>
            )}
            {!ideas && serp && (
              <>
                <th>Pos/Dominio (Hoy)</th>
                <th>Pos/Eco3 (Hoy)</th>
                <th>Top (Hoy)</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {keywords.map((item, index) => (
            <tr key={index}>
              <td>{item.keyword}</td>
              <td>
                {item.minBusqueda}-{item.maxBusqueda}
              </td>
              <td>{item.mediaBusqueda}</td>
              <td>{item.competencia}</td>
              <td>{item.indiceCompetencia}</td>
              <td>{item.cpcBajo}</td>
              <td>{item.cpcAlto}</td>
              {!ideas && (
                <>
                  <td>
                    {item.posDominio}
                    {(Number(item.posDominio) > 0 ||
                      item.serp?.posDominioHoy > 0) && (
                      <>
                        <a href={item.serp.urlDominio} target="_blank">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                {item.serp.urlDominio}
                              </Tooltip>
                            }
                          >
                            <Button variant="link">
                              <BsLink />
                            </Button>
                          </OverlayTrigger>
                        </a>
                        <br />
                        <Sparklines
                          data={sparklinkeData(item.keyword, "dominio")}
                        >
                          <SparklinesLine color="blue" />
                        </Sparklines>
                      </>
                    )}
                  </td>
                  <td>
                    {item.posEco3}
                    {Number(item.posEco3) > 0 && (
                      <>
                        <a href={item.serp?.urlEco3} target="_blank">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                {item.serp.urlEco3}
                              </Tooltip>
                            }
                          >
                            <Button variant="link">
                              <BsLink />
                            </Button>
                          </OverlayTrigger>
                        </a>
                        <br />
                        <Sparklines data={sparklinkeData(item.keyword, "eco3")}>
                          <SparklinesLine color="blue" />
                        </Sparklines>
                      </>
                    )}
                  </td>
                  <td>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        verTop(item.serp?.top, item.keyword, "fecha");
                      }}
                    >
                      Ver
                    </Button>
                  </td>
                </>
              )}
              {!ideas && serp && (
                <>
                  <td>
                    {item.serp.posDominioHoy}
                    {item.serp.urlDominioHoy && (
                      <a href={item.serp.urlDominioHoy} target="_blank">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              {item.serp.urlDominioHoy}
                            </Tooltip>
                          }
                        >
                          <Button variant="link">
                            <BsLink />
                          </Button>
                        </OverlayTrigger>
                      </a>
                    )}
                  </td>
                  <td>
                    {item.serp.posEco3Hoy}
                    {item.serp.urlEco3Hoy && (
                      <a href={item.serp.urlEco3Hoy} target="_blank">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              {item.serp.urlEco3Hoy}
                            </Tooltip>
                          }
                        >
                          <Button variant="link">
                            <BsLink />
                          </Button>
                        </OverlayTrigger>
                      </a>
                    )}
                  </td>
                  <td>
                    {" "}
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        verTop(item.serp?.topHoy, item.keyword, "hoy");
                      }}
                    >
                      Ver
                    </Button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      {serpTop && serpTop.length > 0 && (
        <>
          <h3>{serpTopTitulo}</h3>
          <Table>
            <thead>
              <tr>
                <th>Pos</th>
                <th>Titulo</th>
                <th>Link</th>
                <th>Resumen</th>
              </tr>
            </thead>
            <tbody>
              {serpTop.map((item, index) => (
                <tr key={index}>
                  <td>{item.position}</td>
                  <td>{item.title}</td>
                  <td>
                    {" "}
                    <a href={item.link} target="_blank">
                      {item.link}
                    </a>
                  </td>
                  <td>{item.snippet}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default ResultadoAnalisis;
