import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { config } from "./config";
import { Navigate } from "react-router-dom";

import { useUser } from "./userContext";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [refreshToken, setRefreshToken] = useState(false);
  const [message, setMessage] = useState("");
  const { isAuthenticated, loginUser } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${config.api}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const data = await response.json();
    console.log(data?.message);
    if (data.message === "Login exitoso") {
      loginUser({ username });
    } else {
      setMessage(data.message);
    }

    // Realiza acciones dependiendo de la respuesta del servidor
  };

  return (
    <>
      {isAuthenticated && <Navigate to="/" replace={true} />}
      <Container>
        <Row>
          <Col>
            <h2>Herramienta análisis keyword ECO3</h2>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Refresh Token (solo usuario redaccion.eco3@gmail.com)"
                checked={refreshToken}
                onChange={(e) => setRefreshToken(e.target.checked)}
              ></Form.Check>
            </Form.Group>
            <a
              // "https://accounts.google.com/o/oauth2/v2/auth?client_id=896361706064-gnp5kd1hrfp004uud4m3rapg3cacdmfh.apps.googleusercontent.com&response_type=code&access_type=offline&prompt=consent&redirect_uri=" +
              href={
                "https://accounts.google.com/o/oauth2/v2/auth?client_id=896361706064-gnp5kd1hrfp004uud4m3rapg3cacdmfh.apps.googleusercontent.com&response_type=code" +
                (refreshToken ? "&access_type=offline&prompt=consent" : "") +
                // "&prompt=consent" +
                "&state=" +
                (refreshToken ? "si" : "no") +
                "&redirect_uri=" +
                config.redirectUrl +
                "&scope=profile email" +
                (refreshToken ? " https://www.googleapis.com/auth/adwords" : "")
              }
            >
              Ingresar
            </a>
            {/* <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Usuario</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Constraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              {message && (
                <div className="text-bg-danger p-2 m-2">{message}</div>
              )}
              <Button variant="primary" type="submit">
                Enviar
              </Button>
            </Form> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
