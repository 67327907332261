import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { config } from "./config";
import { Navigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "./components/LoadingSpinner";
import { useUser } from "./userContext";

const Authorize = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, loginUser } = useUser();

  useEffect(() => {
    generarToken();
  }, [loading]);

  const generarToken = async () => {
    console.log("---------- generando token ----");
    const code = searchParams.get("code");
    const state = searchParams.get("state") ?? "no";
    try {
      console.log("code", code);
      console.log("state", state);
      const response = await fetch(
        `${config.api}/authorize?code=${code}&rurl=${config.redirectUrl}&refresh=${state}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log("response", data);
      loginUser(data.userinfo);
    } catch (error) {
      console.log(error);
      setMessage(error?.message ?? "Ocurrió un error generando el token");
    }

    // Realiza acciones dependiendo de la respuesta del servidor
  };

  return (
    <>
      {isAuthenticated && <Navigate to="/" replace={true} />}
      <Container>
        <Row>
          <Col>
            {message === "" && (
              <>
                <LoadingSpinner />
                <h2>Espere un momento...</h2>
              </>
            )}
            {message !== "" && <h2>{message}</h2>}

            {/* <div>Code: {searchParams.get("code")}</div> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Authorize;
