import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Pagination from "react-bootstrap/Pagination";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Dropdown } from "react-bootstrap";
import { config } from "./config";
import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import ResultadoAnalisis from "./components/ResultadoAnalisis";
import LoadingSpinner from "./components/LoadingSpinner";
import { NavLink, Navigate } from "react-router-dom";
import { useUser } from "./userContext";
import GraficoAnalisis from "./components/GraficoAnalisis";
import * as dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const ConsultarModule = () => {
  const { isAuthenticated, user } = useUser();
  const [listado, setListado] = useState([]);
  const [serp, setSerp] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [paginas, setPaginas] = useState(1);
  const [registros, setRegistros] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [verDetalle, setVerDetalle] = useState(false);
  const [verGrafico, setVerGrafico] = useState(false);
  const [seleccionado, setSeleccionado] = useState(false);
  const [idData, setidData] = useState("");
  const [buscarDato, setBuscarDato] = useState("");
  const [show, setShow] = useState(false);
  const [regEliminar, setRegEliminar] = useState(null);
  const [selected_users, set_Selected_users] = useState([
    user.email.replace("@gmail.com", ""),
  ]);
  const usuarios = [
    "Todos",
    "redaccion.eco3",
    "smarti.economia3",
    "gemma.jimeno.economia3",
    "bramirez.eco3",
    "amagiloe3",
    "lauraeconomia3",
  ];

  let items = [];
  for (let number = 1; number <= paginas; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === pagina}
        onClick={() => changePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const changePage = (page) => {
    console.log("Cambiando a pagina", page);
    cargarAnalisis(0, page);
  };

  const toggleUser = (option) => {
    if (selected_users.includes(option)) {
      set_Selected_users(selected_users.filter((item) => item !== option));
    } else {
      set_Selected_users([...selected_users, option]);
    }
  };

  useEffect(() => {
    cargarAnalisis(0);
  }, []);

  useEffect(() => {
    cargarAnalisis(0, 1);
  }, [selected_users]);

  useEffect(() => {
    if (!verDetalle) {
      setVerGrafico(false);
      setidData("");
      setSeleccionado(null);
    }
  }, [verDetalle]);

  useEffect(() => {
    if (idData === "") {
      return;
    }
    setIsLoading(true);
    console.log("cargando listado. url", config.api);
    fetch(`${config.api}/detalle?id=${idData}`)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);

        data.data = JSON.parse(data.data);
        console.log("Seleccionado", data.data);
        if (!data.data.dominioEco3) {
          data.data.dominioEco3 = "https://economia3.com";
        }
        setSeleccionado(data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }, [idData]);

  useEffect(() => {
    if (!seleccionado) return;

    const hoy = new Date().toLocaleDateString("en-GB");
    // console.log("fechas", seleccionado.fechas);
    // console.log("hoy", hoy);
    const keywordsHoy = seleccionado.fechas.find((f) => f.fecha === hoy);
    // console.log("keywordsHoy", keywordsHoy);
    if (keywordsHoy) {
      console.log("Ya esta la data");
      setSerp(keywordsHoy.data);
    } else {
      console.log("Consultando SERP HOY");
      setIsLoading(true);
      const payload = {
        archivo: seleccionado.archivo ?? idData,
        nombre: seleccionado.nombre,
        dominio: seleccionado.dominio,
        dominioEco3: seleccionado.dominioEco3,
        descripcion: seleccionado.descripcion,
        keywords: seleccionado.frases,
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };

      fetch(`${config.api}/serp`, options)
        .then((response) => response.json())
        .then((data) => {
          console.log("serp data", data);
          setIsLoading(false);
          setSerp(data.fechas[data.fechas.length - 1].data);
        })
        .catch((error) => {
          console.error(error);
          console.log("realizando 2do reintento");
          fetch(`${config.api}/serp`, options)
            .then((response) => response.json())
            .then((data) => {
              console.log("serp data", data);
              setIsLoading(false);
              setSerp(data.fechas[data.fechas.length - 1].data);
            })
            .catch((error) => {
              setIsLoading(false);
              console.error(error);
            });
        });
    }
  }, [seleccionado]);

  const cargarAnalisis = async (aumento, paginaDirecta) => {
    let pag = pagina + (aumento ?? 0);
    if (!paginaDirecta) {
      paginaDirecta = 0;
      if (pag < 1) pag = 1;
      setPagina(pag);
    } else {
      pag = paginaDirecta;
      setPagina(paginaDirecta);
    }

    setIsLoading(true);
    console.log("cargando listado. url", config.api);
    fetch(
      `${
        config.api
      }/listado?pag=${pag}&texto=${buscarDato}&usuarios=${selected_users.join(
        ","
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        // console.log("A ordenar");
        setRegistros(data.total);
        setPaginas(Math.ceil(data.total / 10));
        data = data.data.map((i) => {
          i.data = JSON.parse(i.data);
          return i;
        });
        //data = data.sort(ordenarFechas);
        setListado(data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const eliminarAnalisis = async () => {
    handleClose();
    setIsLoading(true);
    console.log("eliminando registro. id", regEliminar.id);
    fetch(`${config.api}/eliminar?id=${regEliminar.id}`)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        console.log("Registro eliminado");
        cargarAnalisis(0);
      })

      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const dominioCorto = (item) => {
    if (!item.dominio) {
      return true;
    }
    return item.dominio?.length < 60;
  };
  // const mostrarDetalle = async (archivo) => {};

  const ordenarFechas = (a, b) => {
    if (
      dayjs(a.data.fecha, "DD/MM/YYYY").isBefore(
        dayjs(b.data.fecha, "DD/MM/YYYY")
      )
    ) {
      return 1;
    } else if (
      dayjs(b.data.fecha, "DD/MM/YYYY").isBefore(
        dayjs(a.data.fecha, "DD/MM/YYYY")
      )
    ) {
      return -1;
    }

    return 0;
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar registro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Esta seguro de que desea eliminar el registro?
          <br />
          <span className="text-danger">{regEliminar?.nombre}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={eliminarAnalisis}>
            SI
          </Button>
          <Button variant="danger" onClick={handleClose}>
            NO
          </Button>
        </Modal.Footer>
      </Modal>
      {!isAuthenticated && <Navigate to="/login" />}
      <Container>
        <Row>
          <Col>
            <h2>Herramienta análisis keyword ECO3</h2>
            <NavLink to="/">Inicio</NavLink>
            <Breadcrumb>
              <Breadcrumb.Item active>Consultar Análisis</Breadcrumb.Item>
            </Breadcrumb>
            <hr></hr>
          </Col>
        </Row>
        <Row>
          <Col>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                Filtrar por usuarios
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {usuarios.map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => toggleUser(option)}
                    active={selected_users.includes(option)}
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col>
            <div>
              <strong>Usuarios: </strong>
              {selected_users.join(", ")}
            </div>
          </Col>
          <Col xs={6}>
            <InputGroup className="mb-3">
              <InputGroup.Text className="lbl-campos">Texto</InputGroup.Text>
              <Form.Control
                placeholder="Introduce aquí el texto a buscar en los campos Descripción y en Keywords"
                type="input"
                value={buscarDato}
                onChange={(e) => {
                  setBuscarDato(e.target.value);
                }}
              />
            </InputGroup>
          </Col>
          <Col>
            <Button
              variant="success"
              onClick={() => {
                setPagina(1);
                cargarAnalisis(0, 1);
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {isLoading && (
          <Row>
            <Col>
              <LoadingSpinner />
            </Col>
          </Row>
        )}
        {!verDetalle && (
          <Row>
            <Col>
              <Table striped>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Usuario</th>
                    <th>Empresa</th>
                    <th>Descripción</th>
                    <th>Dominio</th>
                    <th>Keywords</th>
                    <th colSpan={2}>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {listado.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.fechaStr ??
                          dayjs(item.fecha, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      </td>
                      <td>
                        {item.usuario?.replace("@gmail.com", "") ??
                          "redaccion.eco3"}
                      </td>
                      <td>{item.nombre ?? item.data?.nombre}</td>
                      <td>{item.descripcion ?? item.data?.descripcion}</td>
                      {dominioCorto(item) && (
                        <td>{item.dominio ?? item.data?.dominio}</td>
                      )}
                      {!dominioCorto(item) && (
                        <td>
                          {(item.dominio ?? item.data?.dominio).substring(
                            0,
                            60
                          )}
                          ....
                        </td>
                      )}

                      <td>{item.frases ?? item.data?.frases}</td>
                      <td>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => {
                            setidData(item.id);
                            setVerDetalle(true);
                            // setSeleccionado(item.data);
                          }}
                        >
                          Detalle
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          disabled={
                            user.email !== "redaccion.eco3@gmail.com"
                              ? item.usuario !== user.email
                              : false
                          }
                          onClick={() => {
                            setRegEliminar(item);
                            handleShow();
                          }}
                        >
                          Eliminar
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            {paginas > 1 && (
              <Row>
                <Col>
                  <Pagination size="sm" className="float-end">
                    <Pagination.First
                      disabled={pagina === 1}
                      onClick={() => changePage(1)}
                    />
                    <Pagination.Prev
                      disabled={pagina === 1}
                      onClick={() => changePage(pagina - 1)}
                    />
                    {items}
                    <Pagination.Next
                      disabled={pagina == paginas}
                      onClick={() => changePage(pagina + 1)}
                    />
                    <Pagination.Last
                      disabled={pagina == paginas}
                      onClick={() => changePage(paginas)}
                    />
                  </Pagination>
                  <span className="float-end small mt-1 px-3">
                    {registros} Registros
                  </span>
                </Col>
              </Row>
            )}

            {/* <Row>
              <Col className="">
                <Button
                  variant="dark"
                  size="sm"
                  disabled={pagina === 1}
                  onClick={() => {
                    // setPagina(pagina - 1 == 0 ? 1 : pagina - 1);
                    cargarAnalisis(-1);
                  }}
                >
                  Anterior
                </Button>
              </Col>
              <Col>
                <Button
                  variant="dark"
                  size="sm"
                  onClick={() => {
                    // setPagina(pagina + 1);
                    cargarAnalisis(1);
                  }}
                >
                  Siguiente
                </Button>
              </Col>
            </Row> */}
          </Row>
        )}
        {verDetalle && (
          <Row>
            <Col>
              <Form>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="lbl-campos">
                    Fecha del análisis
                  </InputGroup.Text>

                  <Form.Control
                    disabled
                    placeholder="Fecha del análisis"
                    type="label"
                    value={seleccionado?.fecha ?? ""}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="lbl-campos">
                    Generado por
                  </InputGroup.Text>

                  <Form.Control
                    disabled
                    placeholder="Generador por"
                    type="label"
                    value={seleccionado?.usuario ?? "redccion.eco3"}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="lbl-campos">
                    Empresa del análisis
                  </InputGroup.Text>

                  <Form.Control
                    disabled
                    placeholder="Nombre de la empresa para realizar el análisis"
                    type="label"
                    value={seleccionado?.nombre ?? ""}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="lbl-campos">
                    Descripción del análisis
                  </InputGroup.Text>
                  <Form.Control
                    disabled
                    placeholder="Breve descripción del análisis"
                    type="input"
                    value={seleccionado?.descripcion ?? ""}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="lbl-campos">
                    URL Seguimiento Empresa
                  </InputGroup.Text>
                  <Form.Control
                    disabled
                    placeholder="URL para localizar en los resultados de búsqueda"
                    type="input"
                    value={seleccionado?.dominio ?? ""}
                  />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text className="lbl-campos">
                    URL Seguimiento ECO3
                  </InputGroup.Text>
                  <Form.Control
                    disabled
                    placeholder="URL para localizar en los resultados de búsqueda"
                    type="input"
                    value={seleccionado?.dominioEco3 ?? ""}
                  />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text className="lbl-campos">
                    Listado de palabras clave del análisis
                  </InputGroup.Text>
                  <Form.Control
                    disabled
                    placeholder="Listado de palabras clave separadas por coma"
                    type="input"
                    value={seleccionado?.frases ?? ""}
                  />
                </InputGroup>
              </Form>

              {/* <p>Nombre: {seleccionado?.nombre}</p>
              <p>Descripción: {seleccionado?.descripcion}</p>
              <p>Dominio: {seleccionado?.dominio}</p>
              <p>DominioEco3: {seleccionado?.dominioEco3}</p>
              <p>Keywords: {seleccionado?.frases}</p> */}
              <Button
                variant="info"
                size="sm"
                onClick={() => {
                  setVerDetalle(false);
                }}
              >
                Volver al listado
              </Button>
              <ResultadoAnalisis data={seleccionado} serp={serp} />
              {!verGrafico && (
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => {
                    setVerGrafico(true);
                  }}
                >
                  Ver grafico
                </Button>
              )}
              {verGrafico && (
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => {
                    setVerGrafico(false);
                  }}
                >
                  Ocultar grafico
                </Button>
              )}
              {verGrafico && (
                <GraficoAnalisis data={seleccionado.fechas ?? []} serp={serp} />
              )}
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default ConsultarModule;
