import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import Root from "./routes/root";
import AnalizarModule from "./Analizar";
import ConsultarModule from "./Consultar";
import Login from "./login";
import Authorize from "./authorize";
import { UserProvider } from "./userContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "analizar/",
    element: <AnalizarModule />,
  },
  {
    path: "consultar/",
    element: <ConsultarModule />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "authorize",
    element: <Authorize />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <UserProvider>
    <RouterProvider router={router} />
  </UserProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
