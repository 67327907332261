import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useUser } from "../userContext";
import { config } from "../config";

export default function Root() {
  const { isAuthenticated, user } = useUser();
  console.log("isAuthenticated", isAuthenticated);

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated, user);
  }, [isAuthenticated]);

  return (
    <>
      {!isAuthenticated && <Navigate to="/login" />}
      <Container>
        <Row>
          <Col>
            <h2>Herramienta análisis keyword ECO3</h2>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col>{user?.name}</Col>
        </Row>
        <Row>
          <Col>
            <ul>
              <li>
                <NavLink to="/analizar">Realizar Análisis nuevo</NavLink>;
              </li>
              <li>
                <NavLink to="/consultar">Consultar Análisis existente</NavLink>;
              </li>
              {/* <li>
                <a
                  href={
                    "https://accounts.google.com/o/oauth2/v2/auth?client_id=896361706064-gnp5kd1hrfp004uud4m3rapg3cacdmfh.apps.googleusercontent.com&response_type=code&access_type=offline&prompt=consent&redirect_uri=" +
                    config.redirectUrl +
                    "&scope=https://www.googleapis.com/auth/adwords"
                  }
                >
                  Actualizar token
                </a>
              </li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
}
